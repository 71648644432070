body { 
    font-family: 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
    font-size: 16px;
    background-color: rgb(239, 243, 245);
}

input { 
    text-align: center;
    width: 100%;
}

.divider { 
    height: 50px;
}
.title { 
    text-align: center;
    font-weight: bold;
}
.title h3 { 
    font-weight: bold;
}

th,td { 
    text-align: center;
    padding-top: 4px;
}
.majorlabels { 
    text-align: center;
}

.namemajorcell {
    width: 40%;
    padding-right: 10px;
}
.grademajorcell { 
    width: 15%;
    padding-right: 10px;
}
.unitmajorcell { 
    width: 15%;
    padding-right: 10px;
}
.yearmajorcell { 
    width: 40%;
    padding-right: 10px;
}

label { 
    margin-right: 8px;

}
.namecell { 
    width: 35%;
}
.gradecell { 
    width: 20%;
}

.unitcell { 
    width: 15%;
}

.checkcell { 
    width: 5%; 
}

.titler { 
    text-align: center;

    font-weight: bold;
}


.listelem { 
    margin-top: 4%;
    margin-right: 4%;
    margin-left: 2%;
    font-weight: normal;
    font-size: 14px;
    width: 100%;
    border-style: solid;
    padding: 3%;
}

.listelem li { 
    margin-top: 2%;
}

.inputinfo { 
    text-align: center;
}
.inputinfo input { 
    width: 80%;
}
.inputinfo label { 
    margin-top: 16%;
}


.listelem h4 { 
    text-align: center;
    font-weight: bold;
}

.textalign { 
    text-align: center;
    padding-top: 1.5%;
}

.semlabels { 
    background-color:rgb(43, 45, 192); 
    color: rgb(220, 223, 50);
    text-align: center;
    font-weight: bold;
    font-size: 13px;
}
.col-md-6.semestersys { 
    padding-right: 1%;
    padding-left: 1%;
}
.col-md-4.quarter { 
    padding-right: .5%;
    padding-left: .5%;
}

.summer { 
    text-align: right;
    padding-right: 3%;
    float: right;
    width: 100%;
}
.activateyear{ 
    background-color: white;
    margin-top: 1%;
    border-style: solid;
    color: black;
    border-radius: 0;
    border-color: black;
    border-width: 2px;
    text-align: center;
    height: 40px;
}

.table { 
    width: 100%;
}

.semester { 
    border-style: none;
    padding: 3%;
}

.semesterheader { 
    text-align: center; 
    margin-bottom: 6px;
    font-weight: bolder;
    color: black;
    border-color: black ;
    border-style: solid;
    
}

.classval { 
    padding-right: 2%;
}
.classval input { 
    width: 100%;
}

.gradeval-no { 
    padding-left: 0%;
    padding-right: 2%;
}

.gradeval { 
    padding-left: 1%;
    padding-top: 1%;
    padding-right: 2%;
}
.gradeval select { 
    width: 120%;
}
.unitval { 
    padding-left: 2%;
}
.unitval input { 
    width: 100%;
}

/* GPAScale CSS */ 
.gpalabel { 
    width: 30%;
    float: left;
    padding-top: 3%;
    padding-left: 6%;
}

.gpainput {
    width: 60%;
    float: right;
    border-style: solid;
    border-width: 2px;
}
.gpatitle { 
    font-size: 14px;
    color: rgb(220, 223, 50);
    font-weight: bolder;
    border-style: solid;
    border-color: grey;
    text-align: center;
    background-color: rgb(43, 45, 192);
    margin-top: 10%;
}

.gparow { 
   
    margin-top: 4%;
}

.gpascale { 
    margin-right: 4%;
}

.classesview { 
    padding: 3%;
}

/* Tab CSS */
.nav-tabs a { 
    color: white;
    font-size: 20px;
    background-color: black;
    border-style: solid;
    margin: 1%;
    border-color: black;
    border-radius: 4%;
    padding: 1%;
}


.active { 
    background-color: white !important;
    color: black !important;
    border-style: solid;
    margin-bottom: 10% !important;
    margin-top: 1%;
    text-decoration: none !important;
}
.nav-link.active { 
    border-bottom-style: none;
}

.nav-tabs { 
    margin-bottom: 1%;
    margin-top: 3%;
    border-style: none;
}

.additionalinfo { 
    margin-left: 3%;
    margin-top: 3%;
    padding: 2%;
    background-color: black;
    color: white;
}
.additionalinfo a { 
    color: rgb(136, 201, 212);
}